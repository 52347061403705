import { normalizeText } from './normalizeText'
import { benefitTypes, FilterOptionsType } from '../consts/filterOptions'
import { Discount } from '../types/discount'
import { discountDependsOnRegion, MAINTAGV3Enum } from '../types/mainTagV3'

export const filterDiscounts = (
  allDiscount: Discount[],
  searchText: string,
  filtersSelected: FilterOptionsType,
  filterOptions: FilterOptionsType
) => {
  if (!allDiscount) return allDiscount
  const filteredDiscounts = allDiscount.filter((discount) => {
    if (discount.parentId) return false
    // Filter Search
    let s = searchText?.toLocaleLowerCase()?.replace('#', '') || ''
    s = normalizeText(s)
    if (
      s.length > 2 &&
      !discount?.club?.toLowerCase().includes(s) &&
      !normalizeText(discount?.titulo)?.toLowerCase().includes(s) &&
      !normalizeText(discount?.descripcion)?.toLowerCase().includes(s) &&
      !discount?.ubicacion?.toLowerCase().includes(s) &&
      !discount?.categoria?.toLowerCase().includes(s) &&
      !discount?.diasNormalizados?.toLowerCase().includes(s) &&
      !discount?.tags?.toLowerCase().includes(s) &&
      normalizeText(discount.id.toLowerCase()) !== s
    )
      return false

    // Filter Clubs
    const allClubsIncluded = [0, filterOptions.Tarjetas.length].includes(
      filtersSelected.Tarjetas.length
    )
    const discountClubIsInListOfTarjetas = filterOptions.Tarjetas.includes(discount.club)
    if (
      discount.club && // no sacar aquellos de empresa/providers
      !(allClubsIncluded && discountClubIsInListOfTarjetas) &&
      !filtersSelected.Tarjetas.includes(discount.club)
    )
      return false

    // Filter Days
    if (
      filtersSelected.Dias.length !== filterOptions.Dias.length &&
      filtersSelected.Dias.length !== 0
    ) {
      const matchAnyDaySelected = filtersSelected.Dias.some((day) => {
        return discount?.diasNormalizados?.includes(day)
      })
      if (
        !matchAnyDaySelected &&
        discount?.diasNormalizados !== 'NOT FOUND' &&
        discount?.diasNormalizados !== ''
      )
        return false
    }

    // Filter Location
    if (
      filtersSelected?.Ubicacion?.length !== filterOptions?.Ubicacion?.length &&
      filtersSelected.Ubicacion.length !== 0 &&
      discountDependsOnRegion(discount)
    ) {
      // return false if discount.regiones does not have any of the regiones in filtersSelected.Ubicacion

      const matchAnyLocationSelected = filtersSelected.Ubicacion.some((region) => {
        return discount?.regiones?.includes(region)
      })
      if (!matchAnyLocationSelected) return false
    }

    // Filter Type of benefit. Sí sólo quiere ver descuentos, retornamos false cuando no lo sea
    if (
      // esto nunca puede pasar, pero igual lo dejo por si alguna cookie nos juega mala pasada
      filtersSelected.BenefitType.length !== filterOptions.BenefitType.length &&
      // esto tampoco
      filtersSelected.BenefitType.length !== 0 &&
      // esto sí
      !filtersSelected.BenefitType.includes(benefitTypes.ALL_DISCOUNTS)
    ) {
      const isDiscount = discount.cantidadDescuento
      if (!isDiscount) return false
    }

    if (
      filtersSelected.MinDiscountsAmount !== 0 &&
      discount.cantidadDescuento &&
      parseInt(discount.cantidadDescuento, 10) > 0
    )
      if (parseInt(discount.cantidadDescuento, 10) < filtersSelected.MinDiscountsAmount)
        return false

    // Filtro MainTagV3
    if (
      filtersSelected.MainTagV3.length !== filterOptions.MainTagV3.length &&
      filtersSelected.MainTagV3.length !== 0
    ) {
      if (!discount.mainTagV3) return false
      let discountContainACategory = false
      let discountHasASelectedCategory = false
      const isOtherSelected = filtersSelected.MainTagV3.includes(MAINTAGV3Enum.OTROS)
      discount.mainTagV3.forEach((mt) => {
        if (filterOptions.MainTagV3.includes(mt) && mt !== MAINTAGV3Enum.OTROS)
          discountContainACategory = true
        // TODO: eliminar esto cuando los filtros se limpien en el app.js
        // revisamos que este seleccionada y que exista entre las opciones
        if (filtersSelected.MainTagV3.includes(mt) && filterOptions.MainTagV3.includes(mt))
          discountHasASelectedCategory = true
      })
      // si no esta selecionado "otras" y el descuento no tiene las categorias seleccionadas
      if (!isOtherSelected && !discountHasASelectedCategory) return false
      // si esta selecionado unicamente "otras" y el descuento sí tiene una categoria
      if (isOtherSelected && filtersSelected.MainTagV3.length === 1 && discountContainACategory)
        return false
      // si esta seleccionado "otras" y tmbn otras categorias estan seleccionadas
      // y el descuento sí tiene una categoria pero no tiene una de las categorias seleccionadas
      if (
        isOtherSelected &&
        filtersSelected.MainTagV3.length > 1 &&
        discountContainACategory &&
        !discountHasASelectedCategory
      )
        return false
    }

    // Solo ADMIN: filtrar por OnlyReserved, WithoutLocal, WithoutGMapsResults
    if (filtersSelected.OnlyReserved && !discount.isReservable) return false
    if (filtersSelected.WithoutLocal && discount.local) return false
    if (filtersSelected.WithoutGMapsResults && discount.gMapsResults) return false

    return true
  })

  return filteredDiscounts
}

export const sortDiscount = (discounts: Discount[], sortOption: any) => {
  const discountsCopy = [...discounts]
  const sortedDiscounts = discountsCopy.sort((x, y) => {
    if (sortOption === 'KMS') {
      // ordenamos por distance
      const xDistance = x.XClosestLocation?.distance || 999999999999
      const yDistance = y.XClosestLocation?.distance || 999999999999
      if (xDistance > yDistance) return 1
      if (xDistance < yDistance) return -1
      return 0
    }
    if (sortOption === 'DCTO') {
      // ordenamos por cantidad de descuento
      let xAmount = parseInt(x.cantidadDescuento, 10) || 0
      let yAmount = parseInt(y.cantidadDescuento, 10) || 0
      if (xAmount >= 90) xAmount = 0
      if (yAmount >= 90) yAmount = 0
      if (xAmount < yAmount) return 1
      if (xAmount > yAmount) return -1
      return 0
    }
    return 0
  })
  return sortedDiscounts
}
