import { createContext } from 'react'
import { DEFAULT_FILTERS, FilterOptionsType } from '../consts/filterOptions'
import { UserLocation } from '../types/main'

export type OpenSnackBarProps = {
  message: string
}

export const defaultSnackBarProps: OpenSnackBarProps = {
  message: '',
}

export type UserAuthType = {
  id: string
  secretToken: string
  rut: string
  name?: string
  email?: string
  phoneNumber?: string
  userType?: string
  username?: string
  savedDiscounts?: string[]
}

export type ClubsType = {
  [key: string]: {
    category: string
    id: string
    display: boolean
    img: string
    label: string
    width: number
    order: number
  }
}

interface AppContextType {
  user: string
  clubs: ClubsType
  filtersSelected: FilterOptionsType
  setFiltersSelected: (filters: FilterOptionsType) => void
  usersLocation: UserLocation | null
  setUsersLocation: (location: UserLocation) => void
  auth: UserAuthType | null
  setAuth: (auth: UserAuthType | null) => void
  openSnackBar: boolean
  handleOpenSnackBar: (props: OpenSnackBarProps) => void
}

export const AppContext = createContext<AppContextType>({
  user: '',
  clubs: {},
  filtersSelected: DEFAULT_FILTERS,
  setFiltersSelected: () => {},
  usersLocation: null,
  setUsersLocation: () => {},
  auth: null,
  setAuth: () => {},
  openSnackBar: false,
  handleOpenSnackBar: () => {},
})
