import './intro.css'
import '../../../App.css'
import { useNavigate } from 'react-router-dom'
import { ClubCheckBox } from '../components/ClubCheckBox'
import { IIntro } from './IIntro'
import { logSMEvent, stringifyTarjetas } from '../../../events/logEvent'
import React, { useContext } from 'react'
import { AppContext } from '../../../contexts/AppContext'
import { Switch, CircularProgress } from '@mui/material'
import { handleSwitchState } from '../../../helpers/handleFilterSelection'
import { SMH2, SMP } from '../../../SMComponents/SMText'
import { SMHorizontalDiv, SMVerticalDiv } from '../../../SMComponents/SMView'
import { useFilterOptions } from '../../../hooks/filterOptions'

// const clubsCategoryOrder = ['BANK', 'DIGITAL_WALLET', 'TELCOM', 'VECINO', 'NEWS', 'CAJA']

export const SelectClubs = function () {
  const filterLabel = 'Tarjetas'
  const navigate = useNavigate()
  const { filtersSelected, setFiltersSelected, clubs } = useContext(AppContext)
  const filterOptions = useFilterOptions()
  return (
    <IIntro
      pageName="intro_clubs"
      progressNumber={1}
      onClickBack={() => {
        logSMEvent('CLICK_BACK_IN_CLUBS')
        return navigate('/')
      }}
      onClickNext={() => {
        logSMEvent('CLICK_NEXT_IN_CLUBS', {
          clubs: stringifyTarjetas(filtersSelected.Tarjetas),
        })
        return navigate('/categories')
      }}
    >
      {/* question (title): always in the top center */}
      <SMVerticalDiv sx={{ gap: 30 }}>
        <SMVerticalDiv verticalCenter horizontalCenter>
          <SMH2 center>¿Cuál de estos convenios tienes?</SMH2>
          <SMHorizontalDiv sx={{ justifyContent: 'flex-end', width: '100%' }}>
            <SMP black>Seleccionar todos</SMP>
            <Switch
              checked={filtersSelected[filterLabel]?.length === filterOptions[filterLabel]?.length}
              onClick={() => {
                handleSwitchState(filterLabel, filtersSelected, setFiltersSelected, filterOptions)
              }}
            />
          </SMHorizontalDiv>
        </SMVerticalDiv>
        <SMVerticalDiv>
          {Object.keys(clubs)?.length == 0 ? (
            <SMVerticalDiv horizontalCenter>
              <CircularProgress style={{ margin: 'auto' }} />
              <p>Cargando clubes...</p>
            </SMVerticalDiv>
          ) : (
            <SMVerticalDiv>
              <p className="subtitle-2">Convenios: </p>
              <div className="body">
                {Object.keys(clubs)
                  ?.sort((a, b) => {
                    const orderA = clubs[a].order
                    const orderB = clubs[b].order
                    if (orderA !== undefined && orderB !== undefined) {
                      return orderA - orderB
                    }
                    if (orderA !== undefined) {
                      return -1
                    }
                    if (orderB !== undefined) {
                      return 1
                    }
                    return 0
                  })
                  .map((clubId) => {
                    return <ClubCheckBox key={clubId} club={clubs[clubId]} />
                  })}
              </div>
            </SMVerticalDiv>
          )}
        </SMVerticalDiv>
      </SMVerticalDiv>
    </IIntro>
  )
}
