import { TextField } from '@mui/material'
import React, { useState } from 'react'

import { styled } from '@mui/material/styles'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

import { neutral90White, neutralWhite, primary80White, primaryPrimary } from '../../../style'

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: primaryPrimary,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'black',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: neutral90White,
    },
    '&:hover fieldset': {
      borderColor: primary80White,
    },
    '&.Mui-focused fieldset': {
      borderColor: primaryPrimary,
    },
  },
})

const TextFieldWrapper = styled('div')({
  position: 'relative',
  display: 'inline-flex',
  width: '100%',
  maxWidth: '700px',
})

export function SearchBar({
  search,
  handleChangeSearch,
}: {
  search: string
  handleChangeSearch: (newSearch: string) => void
}) {
  const [isFocused, setFocused] = useState(false)

  const TextFieldIconContainer = styled('div')`
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 50%;
    border-radius: 50%;
    padding: 6px;
    right: 10px;
    transform: translateY(-50%);
    background-color: ${isFocused ? neutralWhite : primaryPrimary};
    color: ${isFocused ? primaryPrimary : neutralWhite};
    box-shadow: ${!isFocused && search ? '0px 2px 4px rgba(180, 181, 186)' : 'none'};
  `
  return (
    <div
      style={{
        display: 'flex',
        flexGrow: 10, // proportion of the space it will take with the AddBusinessRoundedIcon
        justifyContent: 'center',
        alignItems: 'center',
        margin: '10px 10px 8px 10px',
      }}
    >
      <TextFieldWrapper>
        <CssTextField
          variant="outlined"
          fullWidth
          placeholder="Ej: Hamburguesa"
          label={<p style={{ margin: '0px 0px 20px 24px' }}>Buscar descuento</p>}
          InputProps={{
            style: {
              borderRadius: 40,
              backgroundColor: neutralWhite,
            },
          }}
          value={search}
          onChange={(e) => {
            return handleChangeSearch(e.target.value)
          }}
          onFocus={() => {
            return setFocused(true)
          }}
          onBlur={() => {
            return setFocused(false)
          }}
        />
        <TextFieldIconContainer>
          {search && !isFocused ? (
            <CloseRoundedIcon
              onClick={() => {
                return handleChangeSearch('')
              }}
              sx={{ maxHeight: '20px', maxWidth: '20px' }}
            />
          ) : (
            <SearchRoundedIcon sx={{ maxHeight: '20px', maxWidth: '20px' }} />
          )}
        </TextFieldIconContainer>
      </TextFieldWrapper>
    </div>
  )
}
