import { ALL_REGIONES, RegionesEnum } from '../types/regiones'
import { ALLMAINTAGSV3, MAINTAGV3Enum } from '../types/mainTagV3'

export const shortRegionName: {
  [key in RegionesEnum]: string
} = {
  // para el logger
  [RegionesEnum.METROPOLITANA]: 'RM',
  [RegionesEnum.ANTOFAGASTA]: 'ANTO',
  [RegionesEnum.ARAUCANIA]: 'ARA',
  [RegionesEnum.ARICA]: 'ARI',
  [RegionesEnum.ATACAMA]: 'ATA',
  [RegionesEnum.AYSEN]: 'AYS',
  [RegionesEnum.BIOBIO]: 'BIO',
  [RegionesEnum.COQUIMBO]: 'COQ',
  [RegionesEnum.LAGOS]: 'LAG',
  [RegionesEnum.LIBERTADOR]: 'LIB',
  [RegionesEnum.MAGALLANES]: 'MAG',
  [RegionesEnum.MAULE]: 'MAU',
  [RegionesEnum.NUBLE]: 'NUB',
  [RegionesEnum.RIOS]: 'RIO',
  [RegionesEnum.TARAPACA]: 'TAR',
  [RegionesEnum.VALPARAISO]: 'VAL',
  [RegionesEnum.TODAS]: 'TODAS',
}

export const sortOptions = {
  RELEVANCE: 'Más vistos',
  DCTO: 'Mayor descuento',
  KMS: 'Menor distancia',
}

export const benefitTypes = {
  ALL_DISCOUNTS: 'Sí',
  NOT_ALL: 'No',
}

export const mapOptions = {
  INCLUIR_FRANQUICIAS: 'Incluir cadenas',
}

export enum FilterOptionsEnum {
  DIAS = 'Dias',
  MAINTAGV3 = 'MainTagV3',
  UBICACION = 'Ubicacion',
  TARJETAS = 'Tarjetas',
  MIN_DISCOUNT_AMOUNT = 'MinDiscountsAmount',
  BENEFIT_TYPE = 'BenefitType',
  MAP = 'Mapa',
  SORT = 'Sort',
  ONLY_RESERVED = 'OnlyReserved',
  WITHOUT_LOCAL = 'WithoutLocal',
  WITHOUT_G_MAPS_RESULTS = 'WithoutGMapsResults',
}

export enum DaysEnums {
  L = 'L',
  M = 'M',
  W = 'W',
  J = 'J',
  V = 'V',
  S = 'S',
  D = 'D',
}

export type FilterOptionsType = {
  [FilterOptionsEnum.DIAS]: DaysEnums[]
  [FilterOptionsEnum.MAINTAGV3]: MAINTAGV3Enum[]
  [FilterOptionsEnum.UBICACION]: RegionesEnum[]
  [FilterOptionsEnum.TARJETAS]: string[]
  [FilterOptionsEnum.MIN_DISCOUNT_AMOUNT]: number
  [FilterOptionsEnum.BENEFIT_TYPE]: string[]
  [FilterOptionsEnum.MAP]: string[]
  [FilterOptionsEnum.SORT]: string[]
  [FilterOptionsEnum.ONLY_RESERVED]: boolean
  [FilterOptionsEnum.WITHOUT_LOCAL]: boolean
  [FilterOptionsEnum.WITHOUT_G_MAPS_RESULTS]: boolean
}

export const DEFAULT_FILTERS: FilterOptionsType = {
  [FilterOptionsEnum.DIAS]: [],
  [FilterOptionsEnum.MAINTAGV3]: [],
  [FilterOptionsEnum.UBICACION]: [],
  [FilterOptionsEnum.TARJETAS]: [],
  [FilterOptionsEnum.MIN_DISCOUNT_AMOUNT]: 0,
  [FilterOptionsEnum.BENEFIT_TYPE]: [],
  [FilterOptionsEnum.MAP]: [],
  [FilterOptionsEnum.SORT]: [sortOptions.RELEVANCE],
  [FilterOptionsEnum.ONLY_RESERVED]: false,
  [FilterOptionsEnum.WITHOUT_LOCAL]: false,
  [FilterOptionsEnum.WITHOUT_G_MAPS_RESULTS]: false,
}

export const optionLabel: { [key: string]: string } = {
  L: 'Lunes',
  M: 'Martes',
  W: 'Miércoles',
  J: 'Jueves',
  V: 'Viernes',
  S: 'Sábados',
  D: 'Domingos',
  Dias: 'Días',
  MainTagV3: 'Categorías',
  Ubicacion: 'Región',
  BenefitType: '¿Quiéres ver beneficios que no son descuentos?',
  cursos: 'Educación',
  Itau: 'Itaú',
  Sort: 'Ordenar por',
  MinDiscountsAmount: '% de Dcto.',
  Tarjetas: 'Convenios',
  // regiones
  ARICA: 'Arica y Parinacota',
  AYSEN: 'Aysén',
  TARAPACA: 'Tarapacá',
  ANTOFAGASTA: 'Antofagasta',
  ATACAMA: 'Atacama',
  COQUIMBO: 'Coquimbo',
  VALPARAISO: 'Valparaíso',
  METROPOLITANA: 'Metropolitana',
  LIBERTADOR: 'O’Higgins',
  MAULE: 'Maule',
  NUBLE: 'Ñuble',
  BIOBIO: 'Biobío',
  ARAUCANIA: 'La Araucanía',
  RIOS: 'Los Ríos',
  LAGOS: 'Los Lagos',
  MAGALLANES: 'Magallanes y Antártica',
  FullCopec: 'Full Copec',
}

export const daysIndex: { [key: number]: DaysEnums } = {
  0: DaysEnums.D,
  1: DaysEnums.L,
  2: DaysEnums.M,
  3: DaysEnums.W,
  4: DaysEnums.J,
  5: DaysEnums.V,
  6: DaysEnums.S,
}

const ALL_DAYS = [
  DaysEnums.L,
  DaysEnums.M,
  DaysEnums.W,
  DaysEnums.J,
  DaysEnums.V,
  DaysEnums.S,
  DaysEnums.D,
]

export const ALL_FILTERS_OPTIONS = {
  [FilterOptionsEnum.DIAS]: ALL_DAYS,
  [FilterOptionsEnum.MAINTAGV3]: ALLMAINTAGSV3,
  [FilterOptionsEnum.UBICACION]: ALL_REGIONES,
  [FilterOptionsEnum.TARJETAS]: [], // Initialize with an empty array
  [FilterOptionsEnum.MIN_DISCOUNT_AMOUNT]: 0,
  [FilterOptionsEnum.BENEFIT_TYPE]: [benefitTypes.ALL_DISCOUNTS, benefitTypes.NOT_ALL],
  [FilterOptionsEnum.MAP]: [mapOptions.INCLUIR_FRANQUICIAS],
  [FilterOptionsEnum.SORT]: [sortOptions.RELEVANCE, sortOptions.DCTO, sortOptions.KMS],
  [FilterOptionsEnum.ONLY_RESERVED]: false,
  [FilterOptionsEnum.WITHOUT_LOCAL]: false,
  [FilterOptionsEnum.WITHOUT_G_MAPS_RESULTS]: false,
}

export const doesNotAllowMultipleSelection = [
  FilterOptionsEnum.SORT,
  FilterOptionsEnum.BENEFIT_TYPE,
]

export const onlyAdminFilterOptions = [
  FilterOptionsEnum.WITHOUT_LOCAL,
  FilterOptionsEnum.WITHOUT_G_MAPS_RESULTS,
  FilterOptionsEnum.ONLY_RESERVED,
]
