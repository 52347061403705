import React, { useContext, useEffect, useState } from 'react'
import '../../../App.css'
import { handleFilterSelection } from '../../../helpers/handleFilterSelection'
import { AppContext } from '../../../contexts/AppContext'
import { neutralWhite, primary20White } from '../../../style'
import { ICheckBox } from './ICheckBox'
import { MAINTAGV3Enum, MAINTAGV3Icons, MAINTAGV3Label } from '../../../types/mainTagV3'
import { FilterOptionsEnum } from '../../../consts/filterOptions'

const filterLabel = FilterOptionsEnum.MAINTAGV3

export const MainTagV3CheckBox = function ({ mainTagV3 }: { mainTagV3: MAINTAGV3Enum }) {
  const appContext = useContext(AppContext)
  const { filtersSelected, setFiltersSelected } = appContext
  const [checked, setChecked] = useState(false)
  useEffect(() => {
    setChecked(filtersSelected[filterLabel].includes(mainTagV3))
  }, [filtersSelected, mainTagV3])

  const contentColor = checked ? neutralWhite : primary20White

  return (
    <ICheckBox
      checked={checked}
      onClick={() => {
        handleFilterSelection(filterLabel, mainTagV3, filtersSelected, setFiltersSelected)
      }}
      sx={{
        minWidth: '136px',
        maxWidth: '200px',
        minHeight: '136px',
        maxHeight: '200px',
        borderRadius: '20px',
        flexDirection: 'column',
        padding: '22px',
      }}
    >
      {MAINTAGV3Icons({ fontSize: 40, color: contentColor, marginBottom: 5 })[mainTagV3]}
      <p className="subtitle-3" style={{ color: contentColor }}>
        {MAINTAGV3Label[mainTagV3]}
      </p>
    </ICheckBox>
  )
}
