import React, { useContext } from 'react'

import TuneRoundedIcon from '@mui/icons-material/TuneRounded'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'

import { AppContext } from '../../../contexts/AppContext'
import { FilterOptionsEnum, optionLabel } from '../../../consts/filterOptions'
import { neutralBlack, primary20Black, primaryPrimary } from '../../../style'
import FilterButton from './FilterButton'
import { useFilterOptions } from '../../../hooks/filterOptions'
import { MAINTAGV3Enum } from '../../../types/mainTagV3'

export default function FiltersFrame({
  setNavButtonClicked,
  showFilters,
}: {
  setNavButtonClicked: (label: string) => void
  showFilters: boolean
}) {
  // when clicking in a filter it will call setNavButtonClicked with the label of the filter
  // which will open SwipeableEdgeDrawer
  const { filtersSelected } = useContext(AppContext)
  const filterOptions = useFilterOptions()

  let buttonsOrder: {
    label: FilterOptionsEnum
    amountOptionsSelected: number
    maximumOfOptions: number
  }[] = [
    // label has to be the same as filterOptions
    {
      label: FilterOptionsEnum.DIAS,
      amountOptionsSelected: filtersSelected?.Dias?.length,
      maximumOfOptions: filterOptions.Dias?.length,
    },
    {
      label: FilterOptionsEnum.MAINTAGV3,
      amountOptionsSelected: filtersSelected?.MainTagV3?.length,
      maximumOfOptions: filterOptions.MainTagV3?.length,
    },
    {
      label: FilterOptionsEnum.TARJETAS,
      amountOptionsSelected: filtersSelected?.Tarjetas?.length,
      maximumOfOptions: filterOptions.Tarjetas?.length,
    },
    {
      label: FilterOptionsEnum.MIN_DISCOUNT_AMOUNT,
      amountOptionsSelected: filtersSelected?.MinDiscountsAmount > 0 ? 1 : 0,
      maximumOfOptions: 100,
    },
    {
      label: FilterOptionsEnum.UBICACION,
      amountOptionsSelected: filtersSelected.Ubicacion.length,
      maximumOfOptions: filterOptions.Ubicacion?.length,
    },
  ]
  const isRestobarSelected =
    filtersSelected.MainTagV3?.includes(MAINTAGV3Enum.RESTOBAR_PRESENCIAL) ||
    filtersSelected.MainTagV3?.length === 0

  if (!isRestobarSelected) {
    buttonsOrder = buttonsOrder.filter((b) => b.label !== FilterOptionsEnum.UBICACION)
  }

  let styleA: any = {
    // down
    position: 'fixed',
    top: 0,
    WebkitBackdropFilter: 'blur(10px)', // para iOS
    backdropFilter: 'blur(10px)',
    backgroundColor: 'rgba(245, 247, 249, 0.5)',
    left: 0,
    right: 0,
    overflowX: 'auto',
    scrollbarWidth: 'thin',
    whiteSpace: 'nowrap',
    justifyContent: 'flex-start',
    zIndex: 5,
    transition: 'transform 0.3s ease-in-out',
    transform: 'translateY(0px)',
    display: 'flex',
    padding: '74px 0 10px 0',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    paddingLeft: '15px',
  }
  if (!showFilters) {
    styleA = {
      ...styleA,
      transform: 'translateY(-48px)',
    }
  }

  return (
    <div style={styleA}>
      <FilterButton setNavButtonClicked={setNavButtonClicked} label="ALL_FILTERS" number={0}>
        <TuneRoundedIcon style={{ fontSize: '20px', color: primaryPrimary }} />
        <p
          className="body-1"
          style={{ color: primaryPrimary, marginLeft: '5px', marginRight: '6px' }}
        >
          Filtros
        </p>
      </FilterButton>
      {buttonsOrder.map((b) => {
        const label = optionLabel[b.label] || b.label
        const number = b.amountOptionsSelected === b.maximumOfOptions ? 0 : b.amountOptionsSelected
        const numberLabel = number > 0 ? `+${number}` : ''
        return (
          <div
            key={b.label}
            style={{
              display: 'flex',
            }}
          >
            <FilterButton setNavButtonClicked={setNavButtonClicked} label={b.label} number={number}>
              <p className="body-1">{label}</p>
              <p
                className="body-1"
                style={{
                  color: numberLabel ? primary20Black : neutralBlack,
                  marginLeft: numberLabel ? 5 : 0,
                }}
              >
                {numberLabel}
              </p>
              <ArrowDropDownRoundedIcon />
            </FilterButton>
          </div>
        )
      })}
    </div>
  )
}
