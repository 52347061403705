import { doesNotAllowMultipleSelection } from '../consts/filterOptions'

const handleFilterSelection = (filterLabel, option, filtersSelected, setFiltersSelected) => {
  // manejo del estado de los filtros
  // estructura de los filtros es:
  // filtersSelected = {filterLabel: [option, ...], ...}

  const allowMultipleOptionsSelected = !doesNotAllowMultipleSelection.includes(filterLabel)

  const newFiltersSelected = { ...filtersSelected }
  if (allowMultipleOptionsSelected) {
    // si se permiten muchas opciones seleccionadas

    if (filtersSelected[filterLabel].includes(option)) {
      // sacar la option de los filtros seleccionados
      newFiltersSelected[filterLabel] = filtersSelected[filterLabel].filter((f) => {
        return f !== option
      })
    } else {
      // agregar option a los filtros seleccionados
      newFiltersSelected[filterLabel].push(option)
    }
  } else {
    // si sólo se permite una opcion a escoger
    newFiltersSelected[filterLabel] = [option]
  }
  setFiltersSelected(newFiltersSelected)
}

const handleSwitchState = (filterLabel, filtersSelected, setFiltersSelected, filterOptions) => {
  // encontramos primero cual es la posicion inicial del switch
  // por el useEffect de app.js , al cambiar los filtros seleccionados,
  // automaticamente se cambiara el swtich
  let originSwitchState = false
  const newFiltersSelect = { ...filtersSelected }
  if (filterOptions[filterLabel].length === filtersSelected[filterLabel].length) {
    originSwitchState = true
  } else originSwitchState = false

  if (originSwitchState) {
    // lo cambiamos a false, es decir deseleccionamos todas
    newFiltersSelect[filterLabel] = []
  } else {
    newFiltersSelect[filterLabel] = filterOptions[filterLabel]
  }
  setFiltersSelected(newFiltersSelect)
}

export { handleFilterSelection, handleSwitchState }
