import React from 'react'

import ButtonTracked from '../../common/ButtonTracked'
import {
  neutral90White,
  neutralWhite,
  primary20Black,
  primary40White,
  primary80White,
  secondary40Black,
} from '../../../style'
import { isMobile } from '../../../helpers/device'

export default function FilterButton({
  children,
  setNavButtonClicked,
  label,
  number,
}: {
  children: React.ReactNode
  setNavButtonClicked: (label: string) => void
  label: string
  number: number
}) {
  const backgroundColor = number ? primary80White : neutralWhite
  let hoverBackgroundColor = backgroundColor
  if (!isMobile) {
    hoverBackgroundColor = number ? primary40White : neutral90White
  }

  return (
    <ButtonTracked
      buttonname={`filter_${label}`}
      onClick={() => {
        return setNavButtonClicked(label)
      }}
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        textWrap: 'nowrap',
        alignItems: 'center',
        borderRadius: '10px',
        backgroundColor,
        color: number ? primary20Black : secondary40Black,
        border: '1px solid',
        borderColor: number ? 'rgb(0,0,0,0)' : neutral90White,
        margin: '0px 5px',
        minWidth: 'min-content',
        '&:hover': {
          backgroundColor: hoverBackgroundColor,
        },
      }}
    >
      {children}
    </ButtonTracked>
  )
}
