import NearMeRoundedIcon from '@mui/icons-material/NearMeRounded'
import React, { useState, useEffect, useContext, useRef } from 'react'
import { primary40Black, primaryPrimary, neutralWhite } from '../../../../style'
import { DiscountMapCard } from './DiscountMapCard'
import { Map } from './Map'
import { DiscountMarker } from './DiscountMarker'
import ButtonTracked from '../../../common/ButtonTracked'
import { AppContext } from '../../../../contexts/AppContext'
import { Alert, Collapse, Switch } from '@mui/material'
import { getUsersLocation } from '../../../../hooks/useCurrentLocation'
import NoDiscountsFound from '../NoDiscountsFound'
import { mapOptions } from '../../../../consts/filterOptions'
import RestobarMustBeSelectedToSeeMap from '../RestobarMustBeSelectedToSeeMap'
import { MAINTAGV3Enum } from '../../../../types/mainTagV3'
import { SMP } from '../../../../SMComponents/SMText'
import { IS_PROD_ENV } from '../../../../consts/env'
import { Discount } from '../../../../types/discount'
import { GMapsResultsType } from '../../../../types/gMaps'
import { UserLocation } from '../../../../types/main'

const FindMeButton = ({
  mapRef,
  usersLocation,
  setUsersLocation,
  setShowAlert,
}: {
  mapRef: any
  usersLocation: UserLocation | null
  setUsersLocation: (newLocation: UserLocation) => void
  setShowAlert: (newState: boolean) => void
}) => {
  useEffect(() => {
    if (usersLocation?.error) {
      setShowAlert(true)
    } else if (usersLocation?.latitude && usersLocation?.longitude) {
      mapRef.current?.panTo({
        lat: usersLocation.latitude,
        lng: usersLocation.longitude,
      })
    }
  }, [usersLocation])
  return (
    <ButtonTracked
      buttonname="find_me_button"
      onClick={() => {
        getUsersLocation(setUsersLocation)
      }}
      variant="outlined"
      disableRipple={false} // enable wave effect
      style={{
        display: 'flex',
        position: 'absolute',
        top: '140px',
        right: '20px',
        zIndex: 10,
        borderRadius: '10px',
        borderColor: neutralWhite,
        backgroundColor: neutralWhite,
        textTransform: 'none',
        minWidth: '0px',
        margin: '0px',
        padding: '0px',
        // shadow
        boxShadow: 'rgba(0, 0, 0, 0.44) 0px 3px 10px',
      }}
    >
      <NearMeRoundedIcon
        style={{
          fontSize: '30px',
          padding: '6px',
          color: primaryPrimary,
        }}
      />
    </ButtonTracked>
  )
}

const FranquiciasFilterButton = () => {
  const { filtersSelected, setFiltersSelected } = useContext(AppContext)
  return (
    <ButtonTracked
      buttonname="ver_cadenas_button"
      onClick={() => {
        setFiltersSelected({
          ...filtersSelected,
          Mapa: filtersSelected.Mapa.includes(mapOptions.INCLUIR_FRANQUICIAS)
            ? []
            : [mapOptions.INCLUIR_FRANQUICIAS],
        })
      }}
      variant="outlined"
      disableRipple={false} // enable wave effect
      style={{
        display: 'flex',
        position: 'absolute',
        top: '140px',
        left: '20px',
        zIndex: 10,
        borderRadius: '10px',
        borderColor: neutralWhite,
        backgroundColor: neutralWhite,
        textTransform: 'none',
        minWidth: '0px',
        margin: '0px',
        padding: '2px 12px',
        // shadow
        boxShadow: 'rgba(0, 0, 0, 0.44) 0px 3px 10px',
      }}
    >
      <SMP>Ver cadenas</SMP>
      <Switch checked={filtersSelected.Mapa.includes(mapOptions.INCLUIR_FRANQUICIAS)} />
    </ButtonTracked>
  )
}

export function MapScreen({
  isStar,
  discountsToShow,
  isLoadingDiscounts,
  search,
  handleChangeSearch,
  setNavButtonClicked,
}: {
  isStar: boolean
  discountsToShow: Discount[]
  isLoadingDiscounts: boolean
  search: string
  handleChangeSearch: (newSearch: string) => void
  setNavButtonClicked: (newState: string) => void
}) {
  const { usersLocation, setUsersLocation, filtersSelected } = useContext(AppContext)
  const [showAlert, setShowAlert] = useState(false)
  const [focusMap, setFocusMap] = useState(true)
  const [markers, setMarkers] = useState<any[]>([])
  // const [approvedGMapsResults, setApprovedGMapsResults] = useState([])
  // const [removedGMapsResults, setRemovedGMapsResults] = useState([])
  const mapRef = useRef()
  const [selected, setSelected] = useState<{
    discount: Discount
    gMapResult: GMapsResultsType
  } | null>(null)

  const handleMarkerClick = (
    isSelected: boolean,
    discount: Discount,
    gMapResult: GMapsResultsType
  ) => {
    if (isSelected) {
      setSelected(null)
      setFocusMap(true)
    } else {
      setFocusMap(false)
      !IS_PROD_ENV && console.log(discount)
      setSelected({ discount, gMapResult })
    }
  }

  const idMarkerSelected = `${selected?.discount?.id}/${selected?.gMapResult?.location?.latitude}${selected?.gMapResult?.location?.longitude}`

  useEffect(() => {
    const allLocationsDisplayed: string[] = []
    const markersIdsInMap: string[] = []
    const markersInMap = discountsToShow
      .filter((d) => d.gMapsResults && d.cantidadDescuento)
      .filter((d) => d.mainTagV3?.includes(MAINTAGV3Enum.RESTOBAR_PRESENCIAL))
      .filter((d) => {
        if (filtersSelected.Mapa.includes(mapOptions.INCLUIR_FRANQUICIAS)) {
          return true
        } else {
          // filter discounts that have more than 5 locations in gMapsResults
          return d.gMapsResults.length <= 5
        }
      })
      .sort((a, b) => {
        // para que el que tenga mayor descuento este sobre el de menor
        return parseInt(b.cantidadDescuento) - parseInt(a.cantidadDescuento)
      })
      .map((discount) => {
        return discount.gMapsResults.map((gMapResult) => {
          if (!gMapResult?.location) return null
          const location = gMapResult.location
          let locationString = `${location?.latitude}${location?.longitude}`
          const idMarker = `${discount?.id}/${locationString}`
          if (markersIdsInMap.includes(idMarker)) return null
          markersIdsInMap.push(idMarker)
          const isSelected = idMarkerSelected === idMarker
          // const isApproved =
          //   gMapResult?.approved || approvedGMapsResults.includes(gMapResult.place_id)
          // const isRemoved = removedGMapsResults.includes(gMapResult.place_id)
          // black background if none of the gMapResult types is a restaurant
          // const paintBackground =
          //   isStar && !gMapResult.types.some((type) => restaurantTypes.includes(type))
          while (allLocationsDisplayed.includes(locationString)) {
            // change location a little bit to avoid overlapping
            location.latitude += 0.00005
            location.longitude += 0.00005
            locationString = `${location.latitude}${location.longitude}`
          }
          allLocationsDisplayed.push(locationString)

          return (
            <DiscountMarker
              discount={discount}
              handleMarkerClick={() => {
                handleMarkerClick(isSelected, discount, gMapResult)
              }}
              isSelected={isSelected}
              location={location}
              key={idMarker}
              // isApproved={isApproved}
              // isRemoved={isRemoved}
              // paintBackground={paintBackground}
            />
          )
        })
      })
    setMarkers(markersInMap.flat().filter((m) => m))
  }, [discountsToShow, idMarkerSelected, filtersSelected.Mapa])

  if (isLoadingDiscounts) {
    return (
      <div
        style={{
          display: 'flex',
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'center',
          // margin: '0 -16px -16px -16px',
          color: primary40Black,
        }}
      >
        Loading map
      </div>
    )
  }
  if (!filtersSelected?.MainTagV3?.includes(MAINTAGV3Enum.RESTOBAR_PRESENCIAL)) {
    return <RestobarMustBeSelectedToSeeMap />
  }

  if (discountsToShow.length === 0) {
    return (
      <NoDiscountsFound
        search={search}
        handleChangeSearch={handleChangeSearch}
        setNavButtonClicked={setNavButtonClicked}
        isStar={isStar}
      />
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        flexGrow: 1,
        // margin: '0 -16px -16px -16px',
      }}
    >
      <Collapse
        in={showAlert}
        style={{
          top: '120px',
          position: 'absolute',
          width: '100%',
          zIndex: 20,
        }}
      >
        <Alert
          severity="error"
          onClose={() => {
            return setShowAlert(false)
          }}
        >
          Para encontrarte debes habilitar la ubicación en tu dispositivo. Error:{' '}
          {usersLocation?.error}
        </Alert>
      </Collapse>
      <FindMeButton
        mapRef={mapRef}
        usersLocation={usersLocation}
        setUsersLocation={setUsersLocation}
        setShowAlert={setShowAlert}
      />
      <FranquiciasFilterButton />
      <Map
        mapRef={mapRef}
        usersLocation={usersLocation}
        focusMap={() => {
          setFocusMap(true)
        }}
        markers={markers}
      />
      {selected && (
        <DiscountMapCard
          isStar={isStar}
          selected={selected}
          setSelected={setSelected}
          focusMap={focusMap}
          setFocusMap={setFocusMap}
          // approvedGMapsResults={approvedGMapsResults}
          // removedGMapsResults={removedGMapsResults}
          // setApprovedGMapsResults={setApprovedGMapsResults}
          // setRemovedGMapsResults={setRemovedGMapsResults}
        />
      )}
    </div>
  )
}
