import React, { useContext, useEffect, useState } from 'react'
import { Discount } from '../../../../types/discount'
import { neutralWhite, primary90White, primaryPrimary } from '../../../../style'
import { DiscountContext } from '../../../../contexts/DiscountContext'
import { DAYS_ORDER, normalizeDaysToText } from '../../../../helpers/others'
import ButtonTracked from '../../../common/ButtonTracked'
import { AppContext } from '../../../../contexts/AppContext'
import { mapOptions } from '../../../../consts/filterOptions'
import { Switch } from '@mui/material'
import { SMP } from '../../../../SMComponents/SMText'
import { MAINTAGV3Enum } from '../../../../types/mainTagV3'

// import { Rating } from '../map/DiscountMapCard'

const FONT_SIZE = 10
const MAX_PANEL_WIDTH = 300

const CLUBS_COLORS: {
  [key: string]: string
} = {
  Santander: '#FCE0CD',
  'Banco de Chile': '#D4E3F7',
  'Banco Security': '#D7CCFB',
  Itau: '#FADBAD',
  BICE: '#C4EBF9',
  Scotiabank: '#F98E86',
  BCI: '#FDFFD3',
  'Banco Estado': '#D88035',
  CMR: '#D4F0CB',
  Claro: '#D33023', // red
  FullCopec: '#4D86D1', // blue
  Tenpo: '#3F433E', // black
  'Banco Ripley': '#70408D', // purple
  MACH: '#560DDE',
  Entel: '#0F2DF5',
  'Vecino Las Condes': '#EEF0F0',
  'La Tercera': '#8D2B2E',
  'Club Lectores El Mercurio': 'white',
  'Mi Vitacura': '#DBE2A4',
  'La Reina': '#2E657B',
}

const CLUBS_LABEL_COLOR: { [key: string]: string } = {
  Santander: '#B0584E',
  'Banco de Chile': '#374667',
  'Banco Security': '#7E5E9D',
  Itau: '#AB8255',
  BICE: '#374667',
  Scotiabank: '#9E3B30',
  BCI: '#D9A13D',
  'Banco Estado': 'white',
  CMR: '#429F65',
  Claro: 'white',
  FullCopec: 'white',
  Tenpo: '#8FF49E',
  'Banco Ripley': 'white',
  MACH: 'white',
  Entel: 'white',
  'Vecino Las Condes': 'black',
  'La Tercera': 'white',
  'Club Lectores El Mercurio': 'black',
  'La Reina': 'white',
}

// const getAvgRating = (discount: Discount) => {
//   if (!discount.gMapsResults || discount.gMapsResults.length === 0) return 0
//   const results = discount.gMapsResults.filter((r) => !!r.rating)
//   const rating = results.reduce((acc, r) => acc + (r.rating || 0), 0) / results.length // || 0 not possible
//   return rating.toFixed(1)
// }

const DiscountPanel = ({ panel }: { panel: Discount[] }) => {
  const { handleDiscountClick } = useContext(DiscountContext)
  const subTarjetaWritten: string[] = []
  const diasWritten: string[] = []
  const alreadyClubsWritten: string[] = []
  const allDiscountsHaveSameTope: { [key: string]: boolean } = {}

  return (
    <table
      style={{
        backgroundColor: primary90White,
        fontSize: FONT_SIZE,
        textAlign: 'center',
        maxWidth: MAX_PANEL_WIDTH,
      }}
    >
      <thead>
        <tr style={{ backgroundColor: primaryPrimary, color: neutralWhite, flex: '0 0 auto' }}>
          <th>TARJETA</th>
          <th>DÍA</th>
          <th>LOCAL</th>
          <th>DCTO.</th>
          {/* <th>
            <StarRoundedIcon style={{ color: googleMapsStarColor, fontSize: 14 }} />
          </th> */}
          {/* <th>#</th>
          <th>res?</th>
          <th>R</th> */}
          {/* <th>beer?</th>
          <th>book</th> */}
        </tr>
      </thead>
      <tbody>
        {panel.map((discount) => {
          const subTarjetaKey = `${discount.club}-${discount.subTarjeta}`

          // revisamos si todos los descuentos de esa subTarjeta tienen el mismo tope
          // revisamos una única vez por subTarjetaKey
          if (!(subTarjetaKey in allDiscountsHaveSameTope) && discount.topeDescuento) {
            allDiscountsHaveSameTope[subTarjetaKey] = panel
              .filter((d) => d.club === discount.club && d.subTarjeta === discount.subTarjeta)
              .every((d) => d.topeDescuento === discount.topeDescuento)
          }

          const shouldWriteSubTarjeta = !subTarjetaWritten.includes(subTarjetaKey)
          if (shouldWriteSubTarjeta) subTarjetaWritten.push(subTarjetaKey)
          const subTarjetaLabel = discount.subTarjeta?.replaceAll(discount.club, '')
          const amountOfDiscountsWithSubTarjeta = panel.filter(
            (d) => d.club === discount.club && d.subTarjeta === discount.subTarjeta
          ).length
          const shouldWriteClub =
            !alreadyClubsWritten.includes(discount.club) || amountOfDiscountsWithSubTarjeta > 8
          if (!alreadyClubsWritten.includes(discount.club)) {
            alreadyClubsWritten.push(discount.club)
          }

          const diasKey = `${discount.club}-${discount.subTarjeta}-${discount.diasNormalizados}`
          const shouldWriteDays = !diasWritten.includes(diasKey)
          if (shouldWriteDays) diasWritten.push(diasKey)
          const dayLabel = normalizeDaysToText(discount.diasNormalizados)

          let localLabel = discount.local
          if (discount.childrenIds) {
            localLabel = `${discount.local} (${discount.childrenIds.length})`
          }

          return (
            <tr key={discount.id} style={{ backgroundColor: neutralWhite }}>
              {shouldWriteSubTarjeta && (
                <td
                  rowSpan={amountOfDiscountsWithSubTarjeta}
                  style={{
                    maxWidth: '80px',
                    backgroundColor: CLUBS_COLORS[discount.club],
                    color: CLUBS_LABEL_COLOR[discount.club],
                    textAlign: 'center',
                  }}
                >
                  {shouldWriteClub && (
                    <div style={{ marginBottom: 4, fontWeight: 'bold' }}>
                      {discount.club.toUpperCase()}
                    </div>
                  )}
                  <div>{subTarjetaLabel}</div>
                  {allDiscountsHaveSameTope[subTarjetaKey] && (
                    <div style={{ marginTop: 4, fontWeight: 'bold' }}>
                      Tope: {discount.topeDescuento}
                    </div>
                  )}
                </td>
              )}
              {shouldWriteDays && (
                <td
                  rowSpan={
                    panel.filter(
                      (d) =>
                        d.club === discount.club &&
                        d.subTarjeta === discount.subTarjeta &&
                        d.diasNormalizados === discount.diasNormalizados
                    ).length
                  }
                >
                  {dayLabel}
                </td>
              )}
              <td style={{ color: primaryPrimary }} onClick={() => handleDiscountClick(discount)}>
                {localLabel}
              </td>
              {discount.cantidadDescuento ? <td>{discount.cantidadDescuento} %</td> : <td>NA</td>}
              {/* <td>{discount.mainTagV3.join(',')}</td> */}
              {/* <td>
                {new Intl.DateTimeFormat('es-ES', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true,
                }).format(new Date((discount?.gMapsOutputTime?._seconds | 0) * 1000))}
              </td> */}
              {/* <td>{discount.subTypeV2.join(',')}</td> */}
              {/* <td>{discount.regiones.join(' ')}</td> */}
              {/* <td>{getAvgRating(discount)}</td> */}
              {/* <td>{discount.gMapsResults?.length}</td> */}
              {/* <td>{isRestoBarByGMaps ? '' : 'X'}</td> */}
              {/* <td>{discount.tipoConsumo}</td> */}

              {/* <td>{discount.gMapsResults?.map((r) => r.servesBeer).some((s) => s) ? 'Y' : ''}</td>
              <td>{discount.gMapsResults?.map((r) => r.reservable).some((s) => s) ? 'Y' : ''}</td> */}
              {/* <td>{!isRestoBarByGMaps && !discount.childrenIds ? 'X' : ''}</td> */}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const createPanelsAndAssignDiscounts = (discountsByClub: {
  [key: string]: Discount[]
}): Discount[][] => {
  const panels: Discount[][] = []
  const amountOfPanels = Math.max(3, Math.floor(window.innerWidth / MAX_PANEL_WIDTH))
  for (let i = 0; i < amountOfPanels; i++) {
    panels.push([])
  }
  Object.keys(discountsByClub)
    .sort((a, b) => discountsByClub[b].length - discountsByClub[a].length)
    .forEach((club) => {
      const shortesPanelIndex = panels.reduce(
        (acc, panel, index) => (panel.length < panels[acc].length ? index : acc),
        0
      )
      panels[shortesPanelIndex].push(...discountsByClub[club])
    })
  return panels.filter((panel) => panel.length > 0)
}

export const DiscountsTableWithMultiplePanels = ({
  isStar,
  discounts,
  isLoadingDiscounts,
}: {
  isStar: boolean
  discounts: Discount[]
  isLoadingDiscounts: boolean
}) => {
  const { filtersSelected, setFiltersSelected } = useContext(AppContext)
  const [filterDiscountsWithGMapsResults, setFilterDiscountsWithGMapsResults] = useState(false)
  const [discountsToShow, setDiscountsToShow] = useState<Discount[]>(discounts)

  useEffect(() => {
    setDiscountsToShow(
      discounts.filter((d) => {
        const filterCadenas = !filtersSelected.Mapa.includes(mapOptions.INCLUIR_FRANQUICIAS)
        const isCadena =
          d.mainTagV3?.includes(MAINTAGV3Enum.RESTOBAR_PRESENCIAL) && d.gMapsResults?.length > 5
        const hasGMapsResults = d.gMapsResults?.length
        if (filterCadenas && isCadena) return false
        if (filterDiscountsWithGMapsResults && hasGMapsResults) return false
        return true
      })
    )
  }, [discounts, filtersSelected.Mapa, filterDiscountsWithGMapsResults])

  // log the width of the screen
  const discountsSort = discountsToShow.sort(
    (a, b) =>
      a.club?.localeCompare(b.club) ||
      a.subTarjeta?.localeCompare(b.subTarjeta) ||
      (a.diasNormalizados &&
        b.diasNormalizados &&
        DAYS_ORDER.indexOf(a.diasNormalizados[0]) - DAYS_ORDER.indexOf(b.diasNormalizados[0])) ||
      a.diasNormalizados?.localeCompare(b.diasNormalizados) ||
      a.cantidadDescuento?.localeCompare(b.cantidadDescuento)
  )

  const discountsByClub: { [key: string]: Discount[] } = {}

  discountsSort.forEach((discount) => {
    if (!discountsByClub[discount.club]) discountsByClub[discount.club] = []
    discountsByClub[discount.club].push(discount)
  })
  const panels = createPanelsAndAssignDiscounts(discountsByClub)

  if (isLoadingDiscounts) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <p>Loading...</p>
      </div>
    )
  }

  return (
    <>
      {isStar && (
        <div style={{ marginTop: 100, display: 'flex', gap: 10, marginLeft: 20 }}>
          <ButtonTracked
            buttonname="ver_cadenas_button"
            onClick={() => {
              setFiltersSelected({
                ...filtersSelected,
                Mapa: filtersSelected.Mapa.includes(mapOptions.INCLUIR_FRANQUICIAS)
                  ? []
                  : [mapOptions.INCLUIR_FRANQUICIAS],
              })
            }}
            variant="outlined"
            disableRipple={false} // enable wave effect
            style={{
              borderRadius: '10px',
              borderColor: neutralWhite,
              backgroundColor: neutralWhite,
              textTransform: 'none',
              padding: '2px 12px',
              boxShadow: 'rgba(0, 0, 0, 0.44) 0px 3px 10px',
            }}
          >
            <SMP>Ver cadenas</SMP>
            <Switch checked={filtersSelected.Mapa.includes(mapOptions.INCLUIR_FRANQUICIAS)} />
          </ButtonTracked>
          <ButtonTracked
            buttonname="ver_gMapsResults_button"
            onClick={() => {
              setFilterDiscountsWithGMapsResults(!filterDiscountsWithGMapsResults)
            }}
            variant="outlined"
            disableRipple={false} // enable wave effect
            style={{
              borderRadius: '10px',
              borderColor: neutralWhite,
              backgroundColor: neutralWhite,
              textTransform: 'none',
              padding: '2px 12px',
              boxShadow: 'rgba(0, 0, 0, 0.44) 0px 3px 10px',
            }}
          >
            <SMP>Sólo ver sin GMapsResults</SMP>
            <Switch checked={filterDiscountsWithGMapsResults} />
          </ButtonTracked>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: 20,
          gap: 10,
          marginTop: isStar ? 10 : 100,
          paddingBottom: 100,
        }}
      >
        {panels.length === 0 && <p>No hay resultados, revisa los filtros</p>}
        {panels.map((panel, index) => {
          return <DiscountPanel panel={panel} key={index} />
        })}
      </div>
    </>
  )
}
