import React from 'react'

import '../../../App.css'
import { BottomNavigation, BottomNavigationAction } from '@mui/material'

import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import DiscountRoundedIcon from '@mui/icons-material/DiscountRounded'
import { ReactComponent as MapLocationDotSolid } from '../../../assets/icons/MapLocationDotSolid.svg'
import { logSMEvent } from '../../../events/logEvent'
import { DiscountsViewsEnum } from '../../../enums/DiscountsViewsEnum'

export const DiscountsViewBottomNavigationBar = ({
  view,
  setView,
}: {
  view: DiscountsViewsEnum
  setView: (view: DiscountsViewsEnum) => void
}) => {
  return (
    <BottomNavigation
      sx={{
        width: '100%',
        height: '70px',
        zIndex: 10,
        position: 'fixed',
        bottom: 0,
        boxShadow: '0px -3px 5px 0px rgba(0,0,0,0.1)',
      }}
      value={setView}
      onChange={(event, newValue) => {
        logSMEvent('CHANGE_DISCOUNT_PAGE', { page: newValue })
        setView(newValue)
      }}
    >
      <BottomNavigationAction value={DiscountsViewsEnum.LIST} icon={<DiscountRoundedIcon />} />
      <BottomNavigationAction
        value={DiscountsViewsEnum.MAP}
        icon={
          <MapLocationDotSolid fill={view === DiscountsViewsEnum.MAP ? '#3874CB' : '#666666'} />
        }
      />
      <BottomNavigationAction
        value={DiscountsViewsEnum.SAVED_LIST}
        icon={<BookmarkRoundedIcon />}
      />
      <BottomNavigationAction value="account" icon={<PersonRoundedIcon />} />
    </BottomNavigation>
  )
}
