import React from 'react'

import { Checkbox } from '@mui/material'
import { useContext, useEffect, useState } from 'react'

import '../../../App.css'
import { handleFilterSelection } from '../../../helpers/handleFilterSelection'
import { AppContext } from '../../../contexts/AppContext'
import { neutralWhite, primaryPrimary, secondary40Black } from '../../../style'
import { ICheckBox } from './ICheckBox'
import { SMHorizontalDiv } from '../../../SMComponents/SMView'
import { SMCircle } from '../../../SMComponents/SMOthers'
import { SMALinkButton } from '../../../SMComponents/SMButton'
import { isiOS, isAndroid } from '../../../helpers/device'
import { ClubsTypeDB } from '../../../types/clubs'

const filterLabel = 'Tarjetas'

export const ClubCheckBox = function ({ club }: { club: ClubsTypeDB }) {
  const appContext = useContext(AppContext)
  const { filtersSelected, setFiltersSelected } = appContext
  const [checked, setChecked] = useState(false)
  useEffect(() => {
    setChecked(filtersSelected[filterLabel].includes(club.id || ''))
  }, [filtersSelected, club])

  return (
    <ICheckBox
      checked={checked}
      onClick={() => {
        console.log(club)
        handleFilterSelection(filterLabel, club.id, filtersSelected, setFiltersSelected)
      }}
      sx={{
        height: 60,
        minWidth: 280,
        maxWidth: 450,
        borderRadius: 20,
      }}
    >
      {/* Logo with club name */}
      <SMHorizontalDiv sx={{ marginLeft: 16, flex: 1, height: '20px', width: '20px' }}>
        <SMCircle radius={22}>
          <img src={club.img} style={{ width: club.width }} alt="." />
        </SMCircle>
        <p className="subtitle-3" style={{ color: checked ? neutralWhite : secondary40Black }}>
          {club.label}
        </p>
      </SMHorizontalDiv>
      {/* Check Box */}
      {club.label === 'Claro' && (
        <SMALinkButton
          href={
            isiOS
              ? 'https://apps.apple.com/cl/app/mi-claro/id1319458144'
              : isAndroid
              ? 'https://play.google.com/store/apps/details?id=com.miclaro.app&hl=es_CL'
              : 'https://centrodeayudaonline.clarochile.cl/contenido/donde-descargar-app-mi-claro/'
          }
          eventName="CALL_TO_ACTION_CLARO"
          style={{
            fontSize: 10,
            borderRadius: 100,
            backgroundColor: primaryPrimary,
            padding: '4px 10px',
          }}
        >
          Descarga la app
        </SMALinkButton>
      )}
      {club.label === 'Lider Bci' && (
        <SMALinkButton
          href="https://tarjetadigital.liderbciserviciosfinancieros.cl/ingreso-de-datos?utm_source=savemoney&utm_medium=alianza&utm_campaign=savemoney_cta_descuentos"
          eventName="CALL_TO_ACTION_LIDERBCI"
          style={{
            fontSize: 10,
            borderRadius: 100,
            backgroundColor: primaryPrimary,
            padding: '4px 10px',
          }}
        >
          Sácala aquí
        </SMALinkButton>
      )}
      <Checkbox
        style={{
          height: '20px',
          marginRight: 16,
        }}
        checked={checked}
        sx={{
          '&.Mui-checked': {
            color: neutralWhite,
          },
        }}
      />
    </ICheckBox>
  )
}
