import DiscountsHolder from './DiscountsHolder'
import { SuggestedBottoms } from './SuggestedBottoms'
import React from 'react'
import NoDiscountsFound from './NoDiscountsFound'
import { LoadingDiscounts, LoadingDiscountsWithDiscountSelected } from './LoadingDiscounts'
import { Discount } from '../../../types/discount'
import { SMH2 } from '../../../SMComponents/SMText'
import { getUrlParam } from '../../../helpers/others'

export const DiscountScreen = function ({
  isLoadingDiscounts,
  discountsToShow,
  search,
  handleChangeSearch,
  isStar,
  setNavButtonClicked,
  userLevel,
}: {
  isLoadingDiscounts: boolean
  discountsToShow: Discount[]
  search: string
  handleChangeSearch: (search: string) => void
  isStar: boolean
  setNavButtonClicked: (name: string) => void
  userLevel?: number
}) {
  const urlOpenDiscountId = getUrlParam('openDiscountId')
  if (isLoadingDiscounts && urlOpenDiscountId) return <LoadingDiscountsWithDiscountSelected />
  if (isLoadingDiscounts) return <LoadingDiscounts />
  if (discountsToShow.length === 0) {
    return (
      <NoDiscountsFound
        search={search}
        handleChangeSearch={handleChangeSearch}
        setNavButtonClicked={setNavButtonClicked}
        isStar={isStar}
      />
    )
  }
  return (
    <>
      <div style={{ marginTop: '100px' }}>
        {userLevel !== 2 && (
          <>
            <SMH2 center style={{ margin: '12px' }}>
              Tenemos {discountsToShow.length} beneficios para ti
            </SMH2>
            <SuggestedBottoms setNavButtonClicked={setNavButtonClicked} isStar={isStar} />
          </>
        )}
        <DiscountsHolder discountsToShow={discountsToShow} isStar={isStar} />
      </div>
    </>
  )
}
