import React from 'react'

import { DEFAULT_FILTERS, FilterOptionsEnum } from '../../../../consts/filterOptions'
import styles from './filters.module.css'
import ButtonTracked from '../../../common/ButtonTracked'
import { neutral90White } from '../../../../style'
import FilterCardContent from './FilterCardContent'

const { filtersFooterFrame, footerButtons, resultsAmount } = styles

const FilterFooterFrame = function ({
  filterLabel,
  toggleDrawer,
}: {
  filterLabel: string
  toggleDrawer: (open: boolean) => void
}) {
  return (
    <div className={filtersFooterFrame}>
      <div className={resultsAmount} />
      <div className={footerButtons}>
        <ButtonTracked
          buttonname={`filter_card_done_${filterLabel}`}
          variant="contained"
          onClick={() => {
            return toggleDrawer(false)
          }}
          sx={{
            fontWeight: 'bold',
            textTransform: 'none',
            margin: '20px;',
            padding: '4px 20px',
            fontSize: '1rem',
          }}
        >
          Listo
        </ButtonTracked>
      </div>
    </div>
  )
}

const FilterCard = function ({
  filterLabel,
  toggleDrawer,
}: {
  filterLabel: string | false
  toggleDrawer: (open: boolean) => void
}) {
  if (!filterLabel) return <div />
  if (filterLabel === 'ALL_FILTERS') {
    return (
      <div>
        {Object.keys(DEFAULT_FILTERS).map((label) => {
          if (
            label === FilterOptionsEnum.MAP ||
            label === FilterOptionsEnum.SORT ||
            label === FilterOptionsEnum.WITHOUT_G_MAPS_RESULTS ||
            label === FilterOptionsEnum.ONLY_RESERVED ||
            label === FilterOptionsEnum.WITHOUT_LOCAL
          )
            return null
          const borderBottom = label === FilterOptionsEnum.BENEFIT_TYPE ? 'none' : '2px solid'
          return (
            <div
              style={{ padding: '20px 0px', borderBottom, borderColor: neutral90White }}
              key={label}
            >
              <FilterCardContent filterLabel={label} key={label} />
            </div>
          )
        })}
        <div style={{ height: '45px' }} />
        <FilterFooterFrame filterLabel={filterLabel} toggleDrawer={toggleDrawer} />
      </div>
    )
  }
  if (filterLabel === FilterOptionsEnum.MIN_DISCOUNT_AMOUNT) {
    return (
      <div>
        <FilterCardContent filterLabel={FilterOptionsEnum.MIN_DISCOUNT_AMOUNT} />
        <FilterCardContent filterLabel={FilterOptionsEnum.BENEFIT_TYPE} />
        <div style={{ height: '45px' }} />
        <FilterFooterFrame filterLabel={filterLabel} toggleDrawer={toggleDrawer} />
      </div>
    )
  }
  if (filterLabel === FilterOptionsEnum.TARJETAS) {
    return (
      <div>
        <FilterCardContent filterLabel={FilterOptionsEnum.TARJETAS} />
        <div style={{ height: '75px' }} />
        <FilterFooterFrame filterLabel={FilterOptionsEnum.TARJETAS} toggleDrawer={toggleDrawer} />
      </div>
    )
  }
  return (
    <div>
      <FilterCardContent filterLabel={filterLabel} />
      <div style={{ height: '45px' }} />
      <FilterFooterFrame filterLabel={filterLabel} toggleDrawer={toggleDrawer} />
    </div>
  )
}

export default FilterCard
