import { useState, useEffect, useContext } from 'react'
import { AppContext } from '../contexts/AppContext'
import { ALL_FILTERS_OPTIONS, FilterOptionsType } from '../consts/filterOptions'

export const useFilterOptions = () => {
  const { clubs } = useContext(AppContext)

  const [filterOptions, setFilterOptions] = useState<FilterOptionsType>(ALL_FILTERS_OPTIONS)

  useEffect(() => {
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      Tarjetas: Object.keys(clubs).sort((a, b) => {
        const orderA = clubs[a].order
        const orderB = clubs[b].order
        if (orderA !== undefined && orderB !== undefined) {
          return orderA - orderB
        }
        if (orderA !== undefined) {
          return -1
        }
        if (orderB !== undefined) {
          return 1
        }
        return 0
      }),
    }))
  }, [clubs])

  return filterOptions
}
