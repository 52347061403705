import React, { useContext, useState } from 'react'
import SwapVertRoundedIcon from '@mui/icons-material/SwapVertRounded'
import { neutral90White, neutralWhite, primary20Black, primary80Black } from '../../../style'
import FilterButton from './FilterButton'
import { SMP } from '../../../SMComponents/SMText'
import { SMHorizontalDiv } from '../../../SMComponents/SMView'
import { AppContext } from '../../../contexts/AppContext'
import { benefitTypes, sortOptions } from '../../../consts/filterOptions'
import ButtonTracked from '../../common/ButtonTracked'
import { Switch } from '@mui/material'
import { SMPrimaryButton } from '../../../SMComponents/SMButton'
import { request } from '../../../helpers/request'

export const SuggestedBottoms = ({ setNavButtonClicked, isStar }: any) => {
  const { filtersSelected, setFiltersSelected, handleOpenSnackBar } = useContext(AppContext)
  const [isLoadingUpdateBucketFile, setIsLoadingUpdateBucketFile] = useState(false)

  const sortLabel = filtersSelected.Sort[0] || sortOptions.RELEVANCE
  return (
    <SMHorizontalDiv
      sx={{
        marginLeft: 25,
        // wrap the content if it's too long
        flexWrap: 'wrap',
        // overflowX: 'auto',
        // width: 'calc(100% - 25px)',
        gap: 4,
      }}
    >
      {/* Este div permite que el boton no se extienda al maximo en width */}
      <FilterButton setNavButtonClicked={setNavButtonClicked} label="Sort" number={0}>
        <SMHorizontalDiv sx={{ gap: 4 }}>
          <SMP>Ordernar:</SMP>
          <SMP black>{sortLabel}</SMP>
          <SwapVertRoundedIcon style={{ fontSize: '20px', color: primary80Black }} />
        </SMHorizontalDiv>
      </FilterButton>
      <ButtonTracked
        buttonname="mayor_30_percent"
        onClick={() => {
          setFiltersSelected({
            ...filtersSelected,
            BenefitType:
              filtersSelected.MinDiscountsAmount >= 30
                ? [benefitTypes.ALL_DISCOUNTS]
                : [benefitTypes.NOT_ALL],
            MinDiscountsAmount: filtersSelected.MinDiscountsAmount >= 30 ? 0 : 30,
          })
        }}
        variant="outlined"
        disableRipple={false} // enable wave effect
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          textWrap: 'nowrap',
          alignItems: 'center',
          borderRadius: '10px',

          color: primary20Black,
          border: '1px solid',
          margin: '0px 5px',
          minWidth: 'min-content',

          borderColor: neutral90White,
          backgroundColor: neutralWhite,
          textTransform: 'none',
          height: '38px',
          mmargin: '0px 5px',
        }}
      >
        <SMHorizontalDiv sx={{ gap: 0 }}>
          <SMP>Sólo mayor a 30%</SMP>
          <Switch checked={filtersSelected.MinDiscountsAmount >= 30} />
        </SMHorizontalDiv>
      </ButtonTracked>

      {isStar && (
        <>
          <SMPrimaryButton
            onClick={() => {
              setFiltersSelected({
                ...filtersSelected,
                OnlyReserved: !filtersSelected.OnlyReserved,
              })
            }}
            variant="outlined"
            disableRipple={false} // enable wave effect
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              textWrap: 'nowrap',
              alignItems: 'center',
              borderRadius: '10px',
              color: primary20Black,
              border: '1px solid',
              margin: '0px 5px',
              borderColor: neutral90White,
              backgroundColor: neutralWhite,
              textTransform: 'none',
              height: '38px',
              mmargin: '0px 5px',
            }}
          >
            <SMHorizontalDiv sx={{ gap: 0 }}>
              <SMP>Sólo reservable</SMP>
              <Switch checked={filtersSelected.OnlyReserved || false} />
            </SMHorizontalDiv>
          </SMPrimaryButton>
          <SMPrimaryButton
            onClick={() => {
              setFiltersSelected({
                ...filtersSelected,
                WithoutLocal: !filtersSelected.WithoutLocal,
              })
            }}
            variant="outlined"
            disableRipple={false} // enable wave effect
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              textWrap: 'nowrap',
              alignItems: 'center',
              borderRadius: '10px',
              color: primary20Black,
              border: '1px solid',
              margin: '0px 5px',
              borderColor: neutral90White,
              backgroundColor: neutralWhite,
              textTransform: 'none',
              height: '38px',
              mmargin: '0px 5px',
            }}
          >
            <SMHorizontalDiv sx={{ gap: 0 }}>
              <SMP>Sin local</SMP>
              <Switch checked={filtersSelected.WithoutLocal || false} />
            </SMHorizontalDiv>
          </SMPrimaryButton>
          <SMPrimaryButton
            onClick={() => {
              setFiltersSelected({
                ...filtersSelected,
                WithoutGMapsResults: !filtersSelected.WithoutGMapsResults,
              })
            }}
            variant="outlined"
            disableRipple={false} // enable wave effect
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              textWrap: 'nowrap',
              alignItems: 'center',
              borderRadius: '10px',
              color: primary20Black,
              border: '1px solid',
              margin: '0px 5px',
              borderColor: neutral90White,
              backgroundColor: neutralWhite,
              textTransform: 'none',
              height: '38px',
              mmargin: '0px 5px',
            }}
          >
            <SMHorizontalDiv sx={{ gap: 0 }}>
              <SMP>Sin gMapsResults</SMP>
              <Switch checked={filtersSelected.WithoutGMapsResults || false} />
            </SMHorizontalDiv>
          </SMPrimaryButton>
          <SMPrimaryButton
            loading={isLoadingUpdateBucketFile}
            sx={{ backgroundColor: isLoadingUpdateBucketFile ? 'grey' : 'purple' }}
            onClick={async () => {
              setIsLoadingUpdateBucketFile(true)
              const response = await request('update_bucket_file')
              console.log(response?.data || 'no data')
              handleOpenSnackBar({
                message: response?.message || 'Error',
              })
              setIsLoadingUpdateBucketFile(false)
            }}
          >
            WARNING: Actualizar BBDD
          </SMPrimaryButton>
        </>
      )}
    </SMHorizontalDiv>
  )
}
