export const getUrlParam = (param: string) => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(param) || ''
}

export const setUrlParam = (param: string, value: string) => {
  const urlParams = new URLSearchParams(window.location.search)
  urlParams.set(param, value || '')
  window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`)
}

export const DAYS_ORDER = ['L', 'M', 'W', 'J', 'V', 'S', 'D']

export const DAYS_ABREVIATIONS: {
  [key: string]: string
} = {
  'NOT FOUND': 'TODOS',
  '': 'TODOS',
  'L,M,W': 'Lun. a Mié.',
  'L,M,W,J,V': 'Lun. a Vie.',
  'L,M,W,J,V,S': 'Lun. a Sáb.',
  'L,M,W,J,V,S,D': 'TODOS',
  'L,M,W,J': 'Lun. a Jue.',
  'J,V,S': 'Jue. a Sáb.',
  'V,S,D': 'Vie. a Dom.',
  // L: 'Lun.',
  // M: 'Mar.',
  // W: 'Mié.',
  // J: 'Jue.',
  // V: 'Vie.',
  // S: 'Sáb.',
  // D: 'Dom.',
}

export const normalizeDaysToText = (normalizeDays: string): string => {
  if (!normalizeDays) return 'TODOS'
  const daysLabel = normalizeDays
    .split(',')
    .sort((a, b) => DAYS_ORDER.indexOf(a) - DAYS_ORDER.indexOf(b))
    .join(',')
  const newAbreviation = DAYS_ABREVIATIONS[daysLabel] || daysLabel
  return newAbreviation
}

export const addUtmParamsToUrl = (item: { url: string; id?: string }) => {
  try {
    let basedUrl = item.url
    // Check if URL starts with http or https
    if (!/^https?:\/\//i.test(basedUrl)) {
      // If not, prepend 'http://'
      basedUrl = 'http://' + basedUrl
    }
    const utmParams: {
      [key: string]: string
    } = {
      utm_source: 'savemoney',
      utm_medium: 'referral',
      // utm_campaign: item.id,
      utm_term: item.id || '',
    }
    const url = new URL(basedUrl)
    Object.keys(utmParams).forEach((key) => url.searchParams.append(key, utmParams[key]))
    return url.toString()
  } catch (error) {
    return ''
  }
}
